import React, { MouseEvent } from 'react';
import { BaseComponentProps } from '@/types';

type LinkProps = {
  className?: string;
  target?: string;
  href?: string;
  text?: string;
  role?: string;
  onClick?: {
    (event: MouseEvent<HTMLAnchorElement>): void;
  };
  icon?: HTMLSpanElement;
};

export default function Link(props: BaseComponentProps & LinkProps) {
  const { href, icon, target, onClick, text, className, children, role } =
    props;

  return (
    <a
      className={className}
      href={href}
      onClick={onClick}
      target={target}
      role={role}
    >
      {children || (
        <>
          {text} {icon}
        </>
      )}
    </a>
  );
}

Link.defaultProps = {
  icon: null,
  target: null,
  className: `block`,
  href: `/#`,
  text: null,
  role: null,
  onClick: () => null,
};
